const shape1 = `<div class="glow_wrapper"><img
        class="rellax shape-1"
        src="http://pudelekpinkparty24.wpcdn.pl/i/glow_01.png"
        alt=""
        data-rellax-speed="-9.4"
        data-rellax-percentage="0.8""
      /></div>
    `;
const shape2 = `<div class="glow_wrapper"><img
        class="rellax shape-2"
        src="http://pudelekpinkparty24.wpcdn.pl/i/glow_01.png"
        alt=""
        data-rellax-speed="-10.5"
        data-rellax-percentage="0.7"
      /></div>
    `;
const shape3 = `<div class="glow_wrapper"><img
        class="rellax shape-3"
        src="http://pudelekpinkparty24.wpcdn.pl/i/glow_01.png"
        alt=""
        data-rellax-speed="-8.3"
        data-rellax-percentage="0.6"
      /></div>
    `;
const shape4 = `<div class="glow_wrapper"><img
    class="rellax shape-4"
    src="http://pudelekpinkparty24.wpcdn.pl/i/glow_01.png"
    alt=""
    data-rellax-speed="-5"
    data-rellax-percentage="0.5"
/></div>`;
const createGlows = function () {
  
  if (document.querySelector('body')) {
    
    const glowsOuter = document.createElement('div');
    glowsOuter.classList.add('glows-outer');

    const glowsL1 = document.createElement('div');
    glowsL1.classList.add('glows-L1');
    glowsL1.innerHTML = shape1 + shape2 + shape3 + shape4 + shape2;
    
    const glowsR1 = document.createElement('div');
    glowsR1.classList.add('glows-R1');
    glowsR1.innerHTML = shape2 + shape1 + shape4 + shape3 + shape1;

    glowsOuter.prepend(glowsL1);
    glowsOuter.prepend(glowsR1);
    document.querySelector('body').append(glowsOuter)
    
  }
};

export const prepareBkg = () => {
    createGlows();
  const rellax = new Rellax('.rellax');
};
