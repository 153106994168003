export const scrollToEvent = (offset, callback) => {
  const fixedOffset = offset.toFixed();
  const onScroll = function () {
    if (window.scrollY.toFixed() === fixedOffset) {
      window.removeEventListener('scroll', onScroll);
      callback();
    }
  };

  window.addEventListener('scroll', onScroll);
  onScroll();
  window.scrollTo({
    top: offset,
    behavior: 'smooth',
  });
};

export const getIndex = (item) => {
  const nodes = Array.prototype.slice.call(item.parentElement.children);
  return nodes.indexOf(item);
};

export const debounce = (func, delay = 1000) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600) > 0 ? (Math.floor(seconds / 3600)).toString().padStart(2, '0') : '';
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours} ${minutes.toString().padStart(2, '0')} : ${remainingSeconds.toString().padStart(2, '0')}`;
};

export const removeLastWordIfMatch = (text, wordToRemove) => {
  if (text.endsWith(wordToRemove)) {
    return text.substring(0, text.lastIndexOf(wordToRemove)).trim();
  }
  return text;
};
