export const daysCounter = () => {
  const countdownElement = document.querySelector('.wideo-counter[data-countdown]');
  if (!countdownElement) return;

  const endDate = new Date(countdownElement.getAttribute('data-countdown'));
  const container = document.querySelector('.wideo-container');
  const numContainer = countdownElement.querySelector('.wideo-counter__num');

  if (!container || !numContainer) return;

  let counterInterval;

  const pluralizeHours = (hours) => {
    if (hours === 1) return 'godzina';
    if (hours % 10 >= 2 && hours % 10 <= 4 && (hours % 100 < 10 || hours % 100 >= 20)) return 'godziny';
    return 'godzin';
  };

  const pluralizeMinutes = (minutes) => {
    if (minutes === 1) return 'minuta';
    if (minutes % 10 >= 2 && minutes % 10 <= 4 && (minutes % 100 < 10 || minutes % 100 >= 20)) return 'minuty';
    return 'minut';
  };

  const updateCounter = () => {
    const now = new Date();
    const remainingTime = endDate - now;

    if (remainingTime <= 0) {
      clearInterval(counterInterval);
      container.classList.add('counter--hidden');
      numContainer.innerHTML = 'Termin minął.';
      return;
    }

    const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
    const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));

    let timeString = '';

    if (days > 0) {
      timeString = `<strong>${days}</strong> ${days === 1 ? 'dzień' : 'dni'}`;
    } else if (hours > 0) {
      numContainer.classList.add('font--small');
      timeString = `<strong>${hours}</strong> ${pluralizeHours(hours)}`;
    } else if (minutes > 0) {
      numContainer.classList.add('font--small');
      timeString = `<strong>${minutes}</strong> ${pluralizeMinutes(minutes)}`;
    }

    numContainer.innerHTML = timeString;
  };

  updateCounter();
  counterInterval = setInterval(updateCounter, 1000);
};
