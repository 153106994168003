import { scrollToEvent } from '../helpers.js';
import { project } from '../initializeStats.js';
import { linkStats } from '../stats.js';

const removeErrorState = (input) => {
  const checkboxContainer = input.closest('.category-form__checkbox');
  if (checkboxContainer && checkboxContainer.classList.contains('has-error')) {
    checkboxContainer.classList.remove('has-error');
  }
};

document.querySelectorAll('.form-checkbox').forEach((input) => {
  input.addEventListener('change', () => removeErrorState(input));
});

const storage = {
  set: (key, value) => window.localStorage.setItem(key, JSON.stringify(value)),
  get: (key) => JSON.parse(window.localStorage.getItem(key)),
  remove: (key) => window.localStorage.removeItem(key),
};

const checkUrlParams = (key, expectedValue) => {
  const currentUrl = new URL(window.location.href);
  const hasKey = currentUrl.searchParams.has(key);
  let value = null;

  if (hasKey) {
    value = currentUrl.searchParams.get(key);
    currentUrl.searchParams.delete(key);
    window.history.pushState({}, '', currentUrl.toString());
  }

  if (expectedValue === undefined) {
    return value || null;
  }
  return value === expectedValue;
};

const getUserStatus = async (url) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Server responded with status: ${response.status}`);
    }
    return await response.json();
  } catch (err) {
    console.log('Error', err);
    return null;
  }
};

const sendVote = async (data, url) => {
  // console.log(data, url);
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Server responded with status: ${response.status}`);
    }

    const html = await response.text();

    WP.push(() => {
      WP.pwp.customAction({
        ga: {
          event: 'site_interaction',
          eventCategory: 'send_form',
          eventAction: 'ontouchstart' in document.documentElement ? 'tap' : 'click',
          eventLabel: window.location.href,
        },
      });
    });
    return html;
  } catch (err) {
    console.log('Error', err);
    return err;
  }
};

const validateForm = async (form, artForm, url) => {
  const data = {};
  form.querySelectorAll('.btn--main').forEach((btn) => {
    btn.addEventListener('click', async (e) => {
      e.preventDefault();
      let formIsValid = true;
      const buttonIsOneLogin = btn.classList.contains('button-onelogin');
      const selectedItem = form.closest('.category-form__container').querySelector('.teaser');

      data.category = selectedItem.getAttribute('data-category');
      data.candidate = selectedItem.getAttribute('data-candidate');
      data.form_placement = form.getAttribute('data-placement') || 'site';
      data.button = buttonIsOneLogin ? 'onelogin' : 'vote';

      if (buttonIsOneLogin) {
        form.querySelectorAll('.form-checkbox').forEach((input) => {
          if (!input.checked) {
            input.closest('.category-form__checkbox').classList.add('has-error');
            formIsValid = false;
          }
        });
      }

      if (formIsValid) {
        const serverResponse = await sendVote(data, url);
        if (typeof serverResponse === 'string' && serverResponse.startsWith('http')) {
          storage.set('postOneLoginData', data);
          window.location.href = serverResponse;
        } else {
          // eslint-disable-next-line no-param-reassign
          artForm.querySelector('.wrapper').innerHTML = serverResponse;
          if (artForm.querySelector('.wrapper').querySelectorAll('[data-ga360-click]')) {
            linkStats(artForm.querySelector('.wrapper').querySelectorAll('[data-ga360-click]'));
          }
        }
      }
    });
  });
};

const handleSelectCandidate = async (btn, form, artForm, url, delay = null) => {
  // GET STATUS
  const { gotPrize, prizeStock, voted } = await getUserStatus(form.getAttribute('data-status-url'));
  const singleButton = document.querySelector('.category-form__button');
  const buttonsGroup = document.querySelector('.category-form__buttons');
  if (voted !== false && typeof voted === 'string') {
    // eslint-disable-next-line no-param-reassign
    artForm.querySelector('.wrapper').innerHTML = voted;
    if (artForm.querySelector('.wrapper').querySelectorAll('[data-ga360-click]')) {
      linkStats(artForm.querySelector('.wrapper').querySelectorAll('[data-ga360-click]'));
    }
    artForm.classList.add('category-form__container--visible');
    project.isScrollingController(true);
    scrollToEvent(artForm.getBoundingClientRect().top + window.scrollY, () => {
      project.isScrollingController(false);
    });
    return;
  }
  if (gotPrize || !prizeStock) {
    singleButton?.classList.remove('element--hidden');
    buttonsGroup?.classList.add('element--hidden');
  } else {
    buttonsGroup?.classList.remove('element--hidden');
    singleButton?.classList.add('element--hidden');
  }

  if (artForm) {
    const selectedItemHTML = btn.closest('.teaser').cloneNode(true);
    if (artForm.querySelector('.teaser-wrapper--bordered')) {
      // eslint-disable-next-line no-param-reassign
      artForm.querySelector('.teaser-wrapper--bordered').innerHTML = '';
      artForm.querySelector('.teaser-wrapper--bordered').prepend(selectedItemHTML);
      if (artForm.querySelector('.teaser-wrapper--bordered').querySelectorAll('[data-ga360-click]')) {
        linkStats(artForm.querySelector('.teaser-wrapper--bordered').querySelectorAll('[data-ga360-click]'));
      }
    }
    artForm.classList.add('category-form__container--visible');
    project.isScrollingController(true);
    if (delay) {
      setTimeout(() => {
        project.isScrollingController(true);
        scrollToEvent(artForm.getBoundingClientRect().top + window.scrollY, () => {
          project.isScrollingController(false);
        });
      }, 1000);
    } else {
      scrollToEvent(artForm.getBoundingClientRect().top + window.scrollY, () => {
        project.isScrollingController(false);
      });
    }
    await validateForm(form, artForm, url);
  }
};

export const formCategory = async () => {
  const form = document.querySelector('.category-form');
  if (!form) return;
  const url = form.getAttribute('data-entry-url');
  const artForm = document.querySelector('.category-form__container');
  // CHECK URL PARAMS

  // POST 1LOGIN
  const hasPostLogin = checkUrlParams('post_login', '1');
  const postOneLoginData = storage.get('postOneLoginData');
  storage.remove('postOneLoginData');
  // POST EMBED
  const isPostEmbed = checkUrlParams('post_embed', '1');
  const hasPostEmbedCategory = checkUrlParams('category');
  const hasPostEmbedCandidate = checkUrlParams('candidate');
  if (hasPostLogin && postOneLoginData) {
    // FOR TESTING 1LOGIN
    // artForm.querySelector('.wrapper').innerHTML = await sendVote({ ...postOneLoginData, post_login: 1 }, 'http://localhost:8888/glosowanieCategoryOneLogin');
    artForm.querySelector('.wrapper').innerHTML = await sendVote({ ...postOneLoginData, post_login: 1 }, url);
    if (artForm.querySelector('.wrapper').querySelectorAll('[data-ga360-click]')) {
      linkStats(artForm.querySelector('.wrapper').querySelectorAll('[data-ga360-click]'));
    }
    artForm.classList.add('category-form__container--visible');
    setTimeout(() => {
      project.isScrollingController(true);
      scrollToEvent(artForm.getBoundingClientRect().top + window.scrollY, () => {
        project.isScrollingController(false);
      });
    }, 1000);
  } else if (isPostEmbed && hasPostEmbedCategory && hasPostEmbedCandidate) {
    // ?post_embed=1&category=heros&candidate=heros1
    form.setAttribute('data-placement', 'embed');
    if (document.querySelector(`.teaser[data-category=${hasPostEmbedCategory}][data-candidate=${hasPostEmbedCandidate}]`)) {
      const selectedFromEmbed = document.querySelector(`.teaser[data-category=${hasPostEmbedCategory}][data-candidate=${hasPostEmbedCandidate}]`);
      await handleSelectCandidate(selectedFromEmbed.querySelector('.btn-category--vote'), form, artForm, url, true);
    }
  }
  document.querySelectorAll('.btn-category--vote').forEach((btn) => {
    // ETAP 1 - KLIKNIĘCIE I ROZWINIĘCIE FORMULARZA, SPRAWDZENIE STATUSU
    btn.addEventListener('click', () => handleSelectCandidate(btn, form, artForm, url));
  });
};
