import { project } from './initializeStats';
import { statsArticle } from './statsArticle';
import { inViewport } from './elementsInViewport';
import { linkStats, setStatElementCollector, shareStats } from './stats';
import { wideo } from './wideo';
import { menu } from './menu';
import { anchors } from './anchors';
// import { debounce } from './helpers';
import { loadTeasers } from './loadTeasers.js';
import { daysCounter } from './widgets/daysCounter.js';
import { formCategory } from './widgets/formCategory.js';
import { prepareBkg } from './paralax_bg_glow.js';

document.addEventListener('DOMContentLoaded', () => {
  const articlesElement = document.querySelector('.articles');
  // if (articlesElement) {
  //   const heroBg = document.createElement('img');
  //   heroBg.src = 'https://herosi.wpcdn.pl/img/bg/bg-mountains.webp';
  //   heroBg.className = 'hero__bg';
  //   articlesElement.parentNode.insertBefore(heroBg, articlesElement);
  // }



  // if (document.querySelectorAll('.sponsor-list').length > 0) {
  //   document.querySelectorAll('.sponsor-list').forEach((sl) => {
  //     const clone = sl.cloneNode(true);
  //     sl.parentNode.insertBefore(clone, sl.nextSibling);
  //     sl.closest('.sponsor-list__wrapper').classList.add('slider--animated');
  //   });
  // }
  
  anchors();
  statsArticle();
  setStatElementCollector();
  linkStats(document.querySelectorAll('[data-ga360-click]'));
  inViewport();
  shareStats();
  wideo();
  menu();  
  loadTeasers();
  daysCounter();
  formCategory();
  prepareBkg();
  window.addEventListener('beforeunload', project.unloadData.bind(project));
});

// const changeSizesOnResize = () => {
//   // console.log('resize');
// };

// if (window.innerWidth > 768) {
// }

// window.addEventListener('resize', debounce(changeSizesOnResize, 500));

// for testing
// document.querySelector('.header').addEventListener('click', project.unloadData.bind(project));
