import { linkStats } from './stats.js';

export const loadTeasers = async () => {
  const loadMoreButton = document.querySelector('.btn--load');
  if (loadMoreButton) {
    loadMoreButton.addEventListener('click', async () => {
      console.log('cl');
      const previousElement = loadMoreButton.previousElementSibling;

      if (previousElement && previousElement.classList.contains('article-teaser')) {
        const dataLoadUrl = previousElement.getAttribute('data-load-url');

        if (dataLoadUrl) {
          try {
            const response = await fetch(dataLoadUrl);
            if (!response.ok) {
              throw new Error(`Server responded with status: ${response.status}`);
            }
            const html = await response.text();
            const temporaryContainer = document.createElement('div');
            temporaryContainer.innerHTML = html;

            loadMoreButton.insertAdjacentHTML('beforebegin', html);

            const newTeasers = Array.from(document.querySelectorAll('.article-teaser:not([data-initialized])'));
            newTeasers.forEach((teaser) => teaser.setAttribute('data-initialized', 'true'));
            linkStats(newTeasers);

            if (!newTeasers.some((teaser) => teaser.getAttribute('data-load-url'))) {
              loadMoreButton.style.display = 'none';
            }
          } catch (err) {
            console.log('Error', err);
            const errorMessage = document.createElement('div');
            errorMessage.classList.add('error-message');
            errorMessage.innerText = 'Wystąpił błąd podczas ładowania treści. Proszę spróbować ponownie.';
            loadMoreButton.insertAdjacentElement('beforebegin', errorMessage);

            setTimeout(() => {
              errorMessage.remove();
            }, 5000);
          }
        }
      }
    });
  }
};
